<template>
    <div>
        <vs-table
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template v-if="status === 'New'" slot="thead">
                <vs-th sort-key="">
                    <vs-checkbox @click.stop="addAllChecked()" v-model="checkedAll">Select All</vs-checkbox>
                </vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Request Code</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
            </template>

            <template v-if="status === 'Process'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Picking Code</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
                <vs-th sort-key="">Status</vs-th>
            </template>

            <template v-if="status === 'Handover'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Handover Code</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
            </template>

            <template v-if="status === 'Complete'" slot="thead">
                <vs-th>Action</vs-th>
                <vs-th sort-key="">Sales</vs-th>
                <vs-th sort-key="">Item Handover Code</vs-th>
                <vs-th sort-key="">Loading Date</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
                <vs-th sort-key="">Total Qty (UOM)</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status === 'New'" title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                            <vs-checkbox
                                v-if="status === 'New'"
                                :checked="checked.includes(tr.id)"
                                v-on:click="addChecked(tr.id)"
                            ></vs-checkbox>

                            <vs-button size="small" color="primary" icon-pack="feather" icon="icon-printer" title="Print"
                                v-if="status === 'Process'" style="margin-right: 5px" @click="handlePrint(tr.id)" :disabled="tr.status_picking === 0" />
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-edit" title="Edit"
                                v-if="status === 'Process'" style="margin-right: 5px" @click="handleEdit(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm"
                                v-if="status === 'Process'" @click="handleConfirm(tr.id)" :disabled="tr.status_picking === 0"/>
                                
                            <vs-button size="small" color="#d58cff" icon-pack="feather" icon="icon-shopping-cart" title="Handover"
                                v-if="status === 'Handover'" style="margin-right: 5px" @click="handleHandOver(tr.id)" />
                            <vs-button size="small" color="success" icon-pack="feather" icon="icon-check" title="Confirm"
                                v-if="status === 'Handover'" @click="handleConfirm(tr.id)" :disabled="tr.handover_status === '0'"/>

                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye"
                                v-if="status === 'Complete'" title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td v-if="status === 'New'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'New'">{{ tr.code }}</vs-td>
                    <vs-td v-if="status === 'New'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'New'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'New'">{{ tr.quantity_uom }}</vs-td>

                    <vs-td v-if="status === 'Process'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Process'">{{ tr.pick_code }}</vs-td>
                    <vs-td v-if="status === 'Process'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Process'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Process'">{{ tr.quantity_uom }}</vs-td>
                    <vs-td v-if="status === 'Process'">{{ tr.status_picking ? 'Picked' : 'Unpicked' }}</vs-td>

                    <vs-td v-if="status === 'Handover'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Handover'">{{ tr.quantity_uom }}</vs-td>

                    <vs-td v-if="status === 'Complete'">{{ tr.sales_name }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.handover_code }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.date }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.total_sku }}</vs-td>
                    <vs-td v-if="status === 'Complete'">{{ tr.quantity_uom }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button v-if="status === 'New'" color="primary" class="mt-5" @click="handleProcess()">PROCESS</vs-button>
            </div>
        </div>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage"/>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        territoryId: {
            type: Array,
        },
        warehouseId: {
            type: Array,
        },
        salesId: {
            type: Array,
        },
        status: {
            type: String,
            default: "New",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
            checkedAll: false,
            checked: [],
            selectedRouteAssignmentToAssign: [],
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            let territoryIds = [];
            this.territoryId.forEach(val => {
                territoryIds.push(val.id)
            });

            let warehouseIds = [];
            this.warehouseId.forEach(val => {
                warehouseIds.push(val.ID)
            });

            let salesIds = [];
            this.salesId.forEach(val => {
                salesIds.push(val.id)
            });


            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                territory_id: territoryIds,
                warehouse_id: warehouseIds,
                personal_id: salesIds,
                status: this.status,
            }

            this.$vs.loading();
            this.$http
                .post("/api/wms/v1/canvas/loading/data-table", param)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id) {
            if (this.status === 'New') {
                this.$router.push({
                    name: "canvas.loading-detail-new",
                    params: { id: id },
                });
            } else if (this.status === 'Complete') {
                this.$router.push({
                    name: "canvas.loading-detail-complete",
                    params: { id: id },
                });
            }
        },
        handlePrint(id) {
            const routeData = this.$router.resolve({
                name: "canvas.loading-actual-print",
                params: { id: id },
            });
            window.open(routeData.href, "_blank");
        },
        handleEdit(id) {
            this.$router.push({
                name: "canvas.loading-picking-items",
                params: { id: id },
            });

            // this.deleteId = id
            // this.$http.get("/api/wms/v1/canvas/loading/testing-data/" + this.deleteId)
        },
        addChecked(val) {
            if (this.checked.includes(val)) {
                this.checked.splice(this.checked.indexOf(val), 1);
            } else {
                this.checked.push(val);
            }
        },
        addAllChecked() {
            if (this.checkedAll == false) {
                for (var i = 0; i < this.table.data.length; i++) {
                    if (this.checked.includes(this.table.data[i].id)) {
                        //
                    } else {
                        this.checked.push(this.table.data[i].id);
                    }
                }
            } else {
                for (var j = 0; j < this.table.data.length; j++) {
                    this.checked.splice(this.checked.indexOf(this.table.data[j].id), 1);
                }
            }
        },
        handleProcess() {
            if (this.checked.length) {
                console.log(this.checked)
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to process these data(s)?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http
                            .post("/api/wms/v1/canvas/loading/process", {
                                canvas_ids: this.checked
                            })
                            .then(resp => {
                                if (resp.code == 200) {
                                    this.$vs.notify({
                                        title: "Success",
                                        text: "Data(s) has been processed",
                                        color: "success",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-check"
                                    });
                                    this.getData();
                                    this.$vs.loading.close();
                                } else {
                                    this.$vs.notify({
                                        title: "Warning",
                                        text: resp.message,
                                        color: "danger",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle"
                                    });
                                    this.$vs.loading.close();
                                }
                            })
                            .catch((error) => {
                                this.$vs.loading.close();
                                console.log(error);
                            });
                    }
                })
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "Please select at least 1 data !",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle"
                });
            }
        },
        handleConfirm(id) {
            this.deleteId = id;
            if (this.status === 'Process') {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to confirm this data?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http
                            .post("/api/wms/v1/canvas/loading/confirm-process/" + this.deleteId)
                            .then((resp) => {
                                if (resp.code == 200) {
                                    this.$vs.notify({
                                        color: "success",
                                        title: "Success",
                                        text: "The data was successfully confirmed",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                } else {
                                    this.$vs.notify({
                                        color: "danger",
                                        title: "Error",
                                        text: resp.message,
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                }
    
                                this.$vs.loading.close();
                                this.getData();
                            })
                            .catch((error) => {
                                this.$vs.loading.close();
                                console.log(error);
                            });
                    }
                })
            } else if (this.status === 'Handover') {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Confirm`,
                    text: "Are you sure to confirm this data?",
                    accept: () => {
                        this.$vs.loading();
                        this.$http
                            .post("/api/wms/v1/canvas/loading/confirm-handover/" + this.deleteId)
                            .then((resp) => {
                                if (resp.code == 200) {
                                    this.$vs.notify({
                                        color: "success",
                                        title: "Success",
                                        text: "The data was successfully confirmed",
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                } else {
                                    this.$vs.notify({
                                        color: "danger",
                                        title: "Error",
                                        text: resp.message,
                                        position: "top-right",
                                        iconPack: "feather",
                                        icon: "icon-x-circle",
                                    });
                                }
    
                                this.$vs.loading.close();
                                this.getData();
                            })
                            .catch((error) => {
                                this.$vs.loading.close();
                                console.log(error);
                            });
                    }
                })
            }
        },
        handleHandOver(id) {
            this.$router.push({
                name: "canvas.loading-detail-handover",
                params: { id: id },
            });
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
}
</script>