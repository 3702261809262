<template>
    <v-select 
        multiple
        :filterable="true" 
        :options="options" 
        v-model="selected" 
        :placeholder="placeholder"
        @input="val => { onChange(val); }" />
</template>

<script>
import vSelect from "vue-select";
export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        }
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/master/sales",
            options: [],
            selected: []
        };
    },
    methods: {
        get() {
            this.$http
                .get(this.baseUrl, {
                    params: {
                        order: "name",
                        sort: "asc"
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.options.push({ id: 0, label: 'All' });
                        resp.data.records.forEach(record => {
                            record.label = `${record.name}`;
                        });
                        this.options.push(...resp.data.records);

                        this.selected.push({ id: 0, label: 'All' });
                    } else {
                        this.options = []
                        console.log(resp.code);
                    }
                });
        },
        onChange(val) {
            if (!val.length) {
                this.selected[0] = this.options[0]
            } else if (val.length > 1) {
                this.selected = this.selected.filter(item => item.id !== 0);
            }
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        this.get();

    }
};
</script>